// Header.js
import React from 'react';
import About from './About';

function Header({ handleAboutClick, setIsAboutOpen, isAboutOpen, showAbout }) {
    return (
        <header id="masthead" className="header header-1 -fixed without-mobile-search" data-header-fixed="true" data-mobile-header-fixed="true" data-fixed-initial-offset="150">
            <div className="header-wrap page-container">
                <div className="header-wrap-inner">
                    <div className="left-part">
                        <div className="branding">
                            <a className="branding-title titles-typo -undash" href="https://courses.codinedu.com/" rel="home">
                                <div className="logo with-mobile">
                                    <img src="https://courses.codinedu.com/wp-content/uploads/2024/03/Codin-edu-logo.png" className="main-logo light-scheme-logo" alt="CODIN Edu" />

                                </div>
                                <div className="logo-mobile">
                                    <img src="https://courses.codinedu.com/wp-content/uploads/2024/03/Codin.EDU-mobile.png" className="main-logo light-scheme-logo" alt="CODIN Edu" />

                                </div>
                                <div className="logo-sticky">
                                    <img src="https://courses.codinedu.com/wp-content/uploads/2024/03/Codin-edu-logo.png" className="main-logo light-scheme-logo" alt="CODIN Edu" />

                                </div>
                                <div className="logo-sticky-mobile">
                                    <img src="https://courses.codinedu.com/wp-content/uploads/2024/03/Codin.EDU-mobile.png" className="main-logo light-scheme-logo" alt="CODIN Edu" />

                                </div>
                                <div className="logo-dynamic">
                                    <span className="dark hidden">
                                        <img src="https://courses.codinedu.com/wp-content/uploads/2024/03/Codin-edu-logo.png" alt="CODIN Edu" />
                                    </span>
                                    <span className="light hidden">
                                        <img src="https://courses.codinedu.com/wp-content/uploads/2024/03/Codin.EDU-With-Text-v2_dark-mode.png" alt="CODIN Edu" />
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="right-part -mt-[50px] md:-mt-[60px]">
                        <div className='menu-optional -unlist'></div>
                        {showAbout && <>
                            <button className={`md:hidden py-1 md:py-2 px-4 font-press-start  md:text-[1em] whitespace-nowrap transparent-button font-space-grotesk tracking-wider ${isAboutOpen ? 'text-white bg-amber-500' : 'text-black'} font-medium text-[0.6em] `}
                                onClick={handleAboutClick}
                            >
                                <span>About Us</span>
                            </button>
                            {isAboutOpen && <About setIsAboutOpen={setIsAboutOpen} />}
                        </>}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
