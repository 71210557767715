import { Suspense, useEffect, useState, useRef } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, TransformControls, ContactShadows, useGLTF, useCursor } from '@react-three/drei'
import { proxy, useSnapshot } from 'valtio';
import { Collapse, Fade } from '@mui/material';
import logo from '../assets/Codin-edu-logo.png'
import Instruction from './Instruction';
import Footer from './Footer';
import Header from './Header';

let isDropdownOpen = false;
let displayInfo = true;

// Reactive state model, using Valtio ...
const modes = ['translate', 'rotate', 'scale']
const state = proxy({ current: null, mode: 0, selectedCategory: null, interestedModels: new Set(), interestedCategory: new Set() })

//current-->the model which is currently clicked/selected
//mode-->the mode in which the model is currently in
//selectedCategory-->the category which is currently selected
//interestedModels-->the models which are currently interested in
//interestedCategory-->the categories which are currently interested in


const modelCategories = {
  VR_Headset: {
    category_model: 'Spatial Design',
    info: 'Exploring advanced digital methods in design, applied at all scales from a humble gadget to a skyscraper. Dwelling on thoughts of what is design, how it can be bettered and what it can potentially achieve.',
  },
  Bench001: {
    category_model: 'Urban Design',
    info: 'Pondering on the future of our neighbourhoods, blocks, cities and regions. Now better equipped with digital methods of capturing large credible data pools, developing methods to analyse, visualise and consequently apply the findings.',
  },
  mask: {
    category_model: 'Facade Design',
    info: 'What constitutes a modern-day smart facade system? How do we design and construct it? Highlighting the importance of an intelligent and adaptive building, guided by external and internal factors like climate, fabrication, environment, etc.',
  },
  Curly: {
    category_model: 'Parametric Design',
    info: 'Dive into the exciting world of computationally generated design. How can parametric tools help us go beyond simple orthographic forms? How do we utilise these tools for form generation, optimisation, data visualisation and more?',
  },
  Table: {
    category_model: 'Digital Fabrication',
    info: 'Ever wondered how complex building forms get built? How do we utilise upcoming technologies like 3D printing, CNC, robotics? Leveraging the rapid digitisation of the construction industry, embracing the challenges of fluid forms in design.',
  },
  Roundcube001: {
    category_model: 'Sustainability',
    info: 'Sustainability is no longer a mere concept in the design industry. It has become a fundamental necessity introduced much earlier in the designing process. How can advanced parametric tools help us improve through analytical and climate-responsive workflows?',
  },
  Notebook: {
    category_model: 'Digital Architecture',
    info: 'Traversing the blurring lines between the virtual world and reality, this domain explores the latent possibilities of this back and forth from design to data and data to design utilising technologies like AR, VR, 3D printing, Arduino Uno, and many more.',
  },
  Zeppelin: {
    category_model: 'Category8',
    info: 'Lorem ipsum dolor sit amet consectetur. Accumsan elementum sed vestibulum dolor. Malesuada nullam sodales dolor malesuada. Blandit at viverra est netus. Nibh adipiscing semper enim donec cras pulvinar.',
  },
  DNA: {
    category_model: 'Category9',
    info: 'Lorem ipsum dolor sit amet consectetur. Accumsan elementum sed vestibulum dolor. Malesuada nullam sodales dolor malesuada. Blandit at viverra est netus. Nibh adipiscing semper enim donec cras pulvinar.',
  },

  Rocket003: {
    category_model: 'Category10',
    info: 'Lorem ipsum dolor sit amet consectetur. Accumsan elementum sed vestibulum dolor. Malesuada nullam sodales dolor malesuada. Blandit at viverra est netus. Nibh adipiscing semper enim donec cras pulvinar.',
  },

};

function Model({ name, setCategory, setError, ...props }) {
  // Ties this component to the state model
  const snap = useSnapshot(state)
  // Fetching the GLTF, nodes is a collection of all the meshes
  // It's cached/memoized, it only gets loaded and parsed once
  const { nodes } = useGLTF('/final.glb')
  // Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
  const [hovered, setHovered] = useState(false)
  useCursor(hovered)
  const isSelectedCategory = state.selectedCategory && modelCategories[name].category_model === state.selectedCategory;
  const isSelectedModel = snap.current === name;
  const isModelinterested = state.interestedModels.has(name);

  //isselectedcategory-->for category selection
  //isselectedmodel--> the model which is currently clicked/selected

  return (
    <mesh
      // Click sets the mesh as the new target
      onClick={(e) => {
        e.stopPropagation();
        state.current = name
        if (isDropdownOpen) {
          isDropdownOpen = false;
          displayInfo = true
        }
        state.selectedCategory = modelCategories[name].category_model
        setCategory(state.selectedCategory)
        setError(false)
      }}
      // If a click happened but this mesh wasn't hit we null out the target,
      // This works because missed pointers fire before the actual hits
      // onPointerMissed={(e) => e.type === 'click' && (state.current = null)}
      onPointerMissed={(e) => {
        if (e.type === 'click') {
          state.current = null;
          state.selectedCategory = null;
          setCategory(null);
        }
      }}
      // Right click cycles through the transform modes
      onContextMenu={(e) => snap.current === name && (e.stopPropagation(), (state.mode = (snap.mode + 1) % modes.length))}
      onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
      onPointerOut={(e) => setHovered(false)}
      name={name}
      geometry={nodes[name].geometry}
      material={nodes[name].material}
      material-color={isModelinterested ? '#ffa401' : isSelectedModel ? '#a5a3a3' : 'white'}
      {...props}
      dispose={null}
    />
  )
}

function Controls() {
  // Get notified on changes to state
  const snap = useSnapshot(state)
  const scene = useThree((state) => state.scene)
  return (
    <>
      {snap.current && <TransformControls object={scene.getObjectByName(snap.current)} mode={modes[snap.mode]} />}
      {/* makeDefault makes the controls known to r3f, now transform-controls can auto-disable them when active */}
      <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} />
    </>
  )
}

function CameraAnimation() {
  const { camera } = useThree();
  const initialPosition = [0, -10, 100];
  const initialFov = 70;
  const targetPosition = [0, -10, 70];
  const targetFov = 50;
  const duration = 2000;

  const animateCamera = (startTime) => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - startTime;

    if (elapsedTime < duration) {
      const t = elapsedTime / duration;
      const newPosition = initialPosition.map((val, index) =>
        val + (targetPosition[index] - val) * t
      );
      const newFov = initialFov + (targetFov - initialFov) * t;

      camera.position.set(...newPosition);
      camera.fov = newFov;
      camera.updateProjectionMatrix();

      requestAnimationFrame(() => animateCamera(startTime));
    } else {
      camera.position.set(...targetPosition);
      camera.fov = targetFov;
      camera.updateProjectionMatrix();
    }
  };

  useEffect(() => {
    const startTime = Date.now();
    animateCamera(startTime);
  }, [camera]); // Run the effect when the camera changes

  return null; // or an empty <></> if you prefer
}

export default function Explore({ journeyStarted }) {

  const [category, setCategory] = useState(null);
  const [interested, setInterested] = useState([])
  const [toggleinfo, setToggleInfo] = useState(window.innerWidth >= 481)
  const [rotationInfoArrowAngle, setRotationInfoArrowAngle] = useState(0);       //INFO BOX ARROW
  const [rotationFilterArrowAngle, setRotationFilterArrowAngle] = useState(0);   //FILTER ARROW
  const [exploreSearchURL, setexploreSearchURL] = useState('');                   //START JOURNEY URL
  const [error, setError] = useState(false)
  const [isModalOpen, setModalOpen] = useState(true);              //FOR TUTORIAL MODAL
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const handleAboutClick = () => {
    setModalOpen(false)
    if (isDropdownOpen) {
      isDropdownOpen = !isDropdownOpen;
    }
    setIsAboutOpen(true);
    if (displayInfo) {
      displayInfo = false;
      state.selectedCategory = null;
      setCategory(null)
      state.current = null
    }
    setError(false)
  }

  useEffect(() => {
    setModalOpen(true)
    // This code will execute once when the component mounts
    const timeoutId = setTimeout(() => {
      setModalOpen(false);
    }, 4000);

    // Clean up the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);


  useEffect(() => {
    setError(error && interested.length === 0);
  }, [interested, isModalOpen])

  const handleclickModal = () => {
    setIsAboutOpen(false)
    if (isDropdownOpen) {
      isDropdownOpen = !isDropdownOpen;
    }
    setModalOpen(true)
    if (displayInfo) {
      displayInfo = false;
      state.selectedCategory = null;
      setCategory(null)
      state.current = null
    }
    setError(false)
  }

  useEffect(() => {
    const handleResize = () => {
      setToggleInfo(window.innerWidth >= 481);
    }
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  const handleToggleInfo = () => {
    if (window.innerWidth < 481) {
      setToggleInfo(!toggleinfo);
      if (toggleinfo) {
        setRotationInfoArrowAngle(rotationInfoArrowAngle + 180);
      } else {
        setRotationInfoArrowAngle(rotationInfoArrowAngle - 180);
      }
    }
  };

  const toggleDropdown = () => {
    isDropdownOpen = !isDropdownOpen
    if (isDropdownOpen){
      setModalOpen(false)
      setIsAboutOpen(false)
    } 
    if (isDropdownOpen) {
      setRotationFilterArrowAngle(rotationFilterArrowAngle + 180)
      displayInfo = false
    } else {
      setRotationFilterArrowAngle(rotationFilterArrowAngle - 180)
      displayInfo = true
    }

  };

  const handleCategorySelect = (c) => {
    if (state.selectedCategory == c) {
      state.selectedCategory = null;
      state.current = null;
      setCategory(null);
      return;
    }
    setModalOpen(false)
    setIsAboutOpen(false)
    const modelName = Object.keys(modelCategories).find(key => modelCategories[key].category_model === c);
    state.selectedCategory = c;
    state.current = modelName; // Set state.current to the model name associated with the selected category
    setCategory(state.selectedCategory);
    // setDropdownOpen(false);
    isDropdownOpen = false;
    // setDisplayInfo(true);
    displayInfo = true;
    setError(false)

  };

  const handleInterested = () => {
    const isInterested = state.interestedCategory.has(category);

    if (isInterested) {
      state.interestedCategory.delete(category);
      // Remove models related to the category from the set
      state.interestedModels.forEach((model) => {
        if (modelCategories[model].category_model === category) {
          state.interestedModels.delete(model);
        }
      });
    } else {
      state.interestedCategory.add(category);
      Object.keys(modelCategories).forEach((model) => {
        if (modelCategories[model].category_model === category) {
          state.interestedModels.add(model);
        }
      });
    }
    const interestedArray = Array.from(state.interestedCategory);
    setInterested(interestedArray);
    const exploreSearchLink = constructexploreSearchLink(interestedArray);
    setexploreSearchURL(exploreSearchLink);
  };

  const constructexploreSearchLink = (interestedCategories) => {
    const baseexploreSearchURL = 'https://courses.codinedu.com/course-category/you-might-be-interested-in,';
    const encodedCategories = interestedCategories.map((category) =>
      encodeURIComponent(category.replace(/\s+/g, '-'))
    );
    const searchQuery = encodedCategories.join(',');
    return `${baseexploreSearchURL}${searchQuery}`;
  };
  const handleStartJourney = () => {
    let error = interested.length === 0;
    setError(error);

    if (!error && exploreSearchURL !== 'https://courses.codinedu.com/course-category/you-might-be-interested-in,') {
      // Fade out the current page
      // document.body.style.transition = 'opacity 2s ease-out';
      // document.body.style.opacity = '0';
      window.open(exploreSearchURL, '_self');
    }
  };

  return (
    <div className={`h-full w-full ${journeyStarted ? '' : 'opacity-0'} `}>
      <Header setIsAboutOpen={setIsAboutOpen} handleAboutClick={handleAboutClick} isAboutOpen={isAboutOpen} showAbout={true}/>
      <Canvas>
        {journeyStarted && <CameraAnimation />}
        <pointLight position={[100, 100, 100]} intensity={1.3} />
        <hemisphereLight color="#ffffff" groundColor="#b9b9b9" position={[-7, 25, 13]} intensity={0.5} />
        <hemisphereLight color="#ffffff" groundColor="#b9b9b9" position={[7, -10, 40]} intensity={0.2} />

        <Suspense fallback={null}>
          <group position={[0, 10, 0]}>
            <Model name="Curly" position={[10, -17, -30]} rotation={[2, 0, -0]} scale={0.9} setCategory={setCategory} setError={setError} />
            {/* <Model name="DNA" position={[20, 0, -17]} rotation={[1, 1, -2]} setCategory={setCategory} setError={setError} /> */}
            <Model name="Bench001" position={[20, 2, -20]} rotation={[-1, 0, 1]} scale={12} setCategory={setCategory} setError={setError} />
            <Model name="Notebook" position={[-21, -15, -13]} rotation={[2, 0, 1]} setCategory={setCategory} setError={setError} />
            {/* <Model name="Rocket003" position={[18, 15, -25]} rotation={[1, 1, 0]} setCategory={setCategory} setError={setError} /> */}
            <Model name="Roundcube001" position={[-25, -4, 5]} rotation={[1, 0, 0]} scale={0.5} setCategory={setCategory} setError={setError} />
            <Model name="Table" position={[1, -4, -28]} rotation={[1, 0, -1]} scale={0.5} setCategory={setCategory} setError={setError} />
            <Model name="VR_Headset" position={[-30, -15, 10]} rotation={[2, 0, 5]} scale={5} setCategory={setCategory} setError={setError} />
            {/* <Model name="Zeppelin" position={[-20, 10, 10]} rotation={[3, -1, 3]} scale={0.005} setCategory={setCategory} setError={setError} /> */}
            <Model name="mask" position={[17, -10, 0]} rotation={[-0.2, 0, 0]} scale={50} setCategory={setCategory} setError={setError} />

            <ContactShadows rotation-x={Math.PI / 2} position={[0, -35, 0]} opacity={0.25} width={200} height={200} blur={1} far={50} setError={setError} />
          </group>
        </Suspense>
        <Controls />
      </Canvas>
      <Instruction isModalOpen={isModalOpen} setModalOpen={setModalOpen} handleclickModal={handleclickModal} handleAboutClick={handleAboutClick} isAboutOpen={isAboutOpen} setIsAboutOpen={setIsAboutOpen} />
      <div className='absolute top-56 min-[769px]:hidden w-full flex justify-center '>
        <Collapse in={error} >
          <span className="px-8 py-2 font-space-grotesk font-bold text-red-800 border-2 border-red-800 rounded-md text-xs flex justify-center items-center">No Objects Selected</span>
        </Collapse>
      </div>
      {/* For screens more than 768px, use a grid system-------------------------------------------------------- */}
      <div className='top-7 flex flex-col absolute right-8 text-[15px] w-[390px] max-[768px]:hidden font-source-code-pro font-bold pl-4  mt-2 text-amber-500'>SELECT YOUR INTERESTS</div>
      <div className={` w-[390px] h-[235px] flex flex-col absolute top-24 right-8 ${state.selectedCategory == null ? 'space-y-7' : 'space-y-3'} items-start max-[768px]:hidden font-space-grotesk text-sm z-[1000]`}>
        {Object.keys(modelCategories).slice(0, 7).map((key) => {
          const { category_model } = modelCategories[key];

          return (
            <div key={key} className={`w-[390px] pl-4 ${state.selectedCategory == category_model ? (state.interestedCategory.has(category_model) ? 'info-box-interested  p-4 pt-0  border border-white' : 'info-box border  p-4 pt-0 border-white') : ''}`}>
              <button
                className={`capitalize text-[1.3em] font-space-grotesk font-medium tracking-wider mt-4  text-black ${state.selectedCategory == category_model ? '' : 'un'} ${state.selectedCategory != category_model && state.interestedCategory.has(category_model) ? 'text-[#ffa401] shadow-interested font-extrabold ' : ''}`}
                onClick={() => handleCategorySelect(category_model)}
              >
                {category_model}
              </button>
              <Collapse in={state.selectedCategory == category_model}>
                <div>
                  <Fade in={state.selectedCategory == category_model} timeout={100}>
                    <div className='mt-3.5 text-xs md:text-sm font-space-grotesk font-normal'>
                      {modelCategories[state.current]?.info}
                    </div>
                  </Fade>
                  <button className={`flex items-center w-full py-2 px-8 border border-white  text-black rounded-lg mt-2 hover:bg-[#ffa401] justify-between font-space-grotesk tracking-wider font-bold text-[1.3em] ${state.interestedCategory.has(category) ? 'interested' : 'not-interested'}`} onClick={() => handleInterested()}>
                    {interested.includes(category) ?
                      <>
                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.5 19.5H4.5V8.1H0.5V19.5ZM22.5 9.05C22.5 8.005 21.6 7.15 20.5 7.15H14.19L15.14 2.8085L15.17 2.5045C15.17 2.115 15 1.754 14.73 1.4975L13.67 0.5L7.09 6.7605C6.72 7.1025 6.5 7.5775 6.5 8.1V17.6C6.5 18.645 7.4 19.5 8.5 19.5H17.5C18.33 19.5 19.04 19.025 19.34 18.341L22.36 11.6435C22.45 11.425 22.5 11.197 22.5 10.95V9.05Z" fill="#595959" />
                        </svg>
                        Form Follows Interest
                      </>
                      :
                      <>
                        <svg width="23" height="20" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.3413 4.62L13.6525 8.11208C13.5075 8.825 13.7009 9.56208 14.16 10.1179C14.6192 10.6738 15.2959 11 16.0209 11H22.6667V12.305L19.5613 19.4583H9.78585C9.56835 19.4583 9.37502 19.265 9.37502 19.0475V9.57417L14.3413 4.62ZM15.4167 0.125L7.67127 7.87042C7.2121 8.32958 6.95835 8.94583 6.95835 9.58625V19.0475C6.95835 20.6063 8.2271 21.875 9.78585 21.875H19.5734C20.4313 21.875 21.2167 21.4279 21.6517 20.7029L24.8779 13.2717C25.0109 12.9696 25.0834 12.6433 25.0834 12.305V11C25.0834 9.67083 23.9959 8.58333 22.6667 8.58333H16.0209L17.1325 2.96458C17.1929 2.69875 17.1567 2.40875 17.0359 2.16708C16.7579 1.62333 16.4075 1.12792 15.9725 0.692917L15.4167 0.125ZM3.33335 8.58333H0.916687V21.875H3.33335C3.99794 21.875 4.54169 21.3313 4.54169 20.6667V9.79167C4.54169 9.12708 3.99794 8.58333 3.33335 8.58333Z" fill="#595959" />
                        </svg>
                        I'm Interested
                      </>}
                  </button>
                </div>
              </Collapse>
              {/* : null
              } */}
            </div>
          );
        })}
      </div>
      <div className={`absolute z-50 bottom-6 right-20 font-space-grotesk ${state.selectedCategory == null ? 'pb-6' : 'pb-4'} pr-4 max-[768px]:hidden`}>
        <Collapse in={error}>
          <div className='w-full flex items-center justify-center'>
            <span className="px-6 py-1 mb-1 font-source-code-pro font-bold text-red-800 text-sm flex justify-center items-center">ERROR: NO OBJECTS SELECTED</span>
          </div>
        </Collapse>
        <button className={`w-[320px] text-[1.3em] font-bold tracking-wider border border-white rounded-lg ${error ? 'bg-[#A70000] error-bg' : interested.length !== 0 ? 'bg-black' : 'bg-[#888888]'} black-button flex items-center justify-between px-4`} onClick={handleStartJourney}>
          <span >Show Courses</span>
          <svg width="50" height="50" viewBox="0 0 100 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_bd_350_20)">
              <path d="M48.5828 35.0003V19L76.2347 36.2944L48.5828 53.5888V37.5886L23 53.5888L23 19L48.5828 35.0003Z" fill="white" />
              <path d="M48.3176 35.4242L49.0828 35.9027V35.0003V19.9025L75.2918 36.2944L49.0828 52.6864V37.5886V36.6861L48.3176 37.1647L23.5 52.6864L23.5 19.9025L48.3176 35.4242Z" stroke="url(#paint0_linear_350_20)" />
            </g>
            <defs>
              <filter id="filter0_bd_350_20" x="0" y="0" width="99.2349" height="80.5889" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_350_20" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_350_20" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="effect1_backgroundBlur_350_20" result="effect2_dropShadow_350_20" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_350_20" result="shape" />
              </filter>
              <linearGradient id="paint0_linear_350_20" x1="43.6956" y1="26.498" x2="7.74571" y2="50.8797" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0.8" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </button>
      </div>
      <div className="fixed bottom-0 left-0 w-full mx-1 font-space-grotesk text-xs min-[769px]:hidden">
        {/* For screens less than 768px, use a grid system -----------------------------------------------------*/}
        <Collapse in={isDropdownOpen}>
          <div className='ml-4'>
            {/*  dropdown content goes here */}
            <ul>
              {Object.keys(modelCategories).slice(0, 7).map((key) => {
                const { category_model } = modelCategories[key];
                return (
                  <li className='py-2' key={key}>
                    <button
                      key={key}
                      className={`${category === category_model ? 'font-extrabold' : ''} ${state.interestedCategory.has(category_model) ? 'text-[#ffa401] shadow-interested font-bold' : 'black'}`}
                      onClick={() => handleCategorySelect(category_model)}
                    >
                      {category_model}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </Collapse>
        <div className="min-[769px]:hidden mb-[3.5rem]">
          <div className='flex items-center justify-between w-full'>
            <div className='flex items-center'>
              <button
                className="ml-1 flex items-center py-2 px-2 font-space-grotesk tracking-wider text-black font-bold text-[0.8em] transparent-button"
                onClick={toggleDropdown}
              >
                <span className="ml-1 pr-1">
                  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(${rotationFilterArrowAngle}deg)`, transition: 'transform 0.5s ease' }}>
                    <path d="M1.0575 6L4.5 2.2915L7.9425 6L9 4.8583L4.5 0L0 4.8583L1.0575 6Z" fill="black" />
                  </svg>
                </span>
                <span>Interests</span>
              </button>
              <button
                className="ml-2 flex items-center py-2 px-3 font-space-grotesk tracking-wider text-black font-bold text-[0.8em]  whitespace-nowrap transparent-button"
                onClick={handleclickModal}
              >
                <span>? Help</span>
              </button>
            </div>
            <div className='py-2 px-4 font-space-grotesk tracking-wider text-black font-semibold text-[0.8em] '>
              <button className={`black-button ${error ? 'bg-[#A70000] error-bg' : interested.length !== 0 ? 'bg-black' : 'bg-[#888888]'} text-[10px] min-[387px]:text-xs whitespace-nowrap  px-4 py-2`} onClick={handleStartJourney}>Show Courses</button>
            </div>
          </div>
        </div>
        {state.selectedCategory && (
          <div className='min-[769px]:hidden'>
            <div className='absolute bottom-32 left-1/2 transform -translate-x-1/2  min-[480px]:w-[436px] w-[90%] '>
              <Collapse in={displayInfo}>
                <div className={` p-4  border border-white ${state.interestedCategory.has(category) ? 'info-box-interested' : 'info-box'}`}>
                  <div className='flex items-center justify-between'>
                    <div onClick={() => handleToggleInfo()} >
                      <div className='font-space-grotesk tracking-wider text-black font-medium text-[1.3em] '>
                        {category}
                      </div>
                    </div>
                    <div className='flex items-center min-[481px]:hidden '>
                      <Fade in={toggleinfo === false} timeout={800}>
                        <div className='border border-white rounded-full p-4 backdrop-blur-sm' onClick={() => handleInterested()}>
                          {interested.includes(category) ?
                            <svg width="20" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.5 19.5H4.5V8.1H0.5V19.5ZM22.5 9.05C22.5 8.005 21.6 7.15 20.5 7.15H14.19L15.14 2.8085L15.17 2.5045C15.17 2.115 15 1.754 14.73 1.4975L13.67 0.5L7.09 6.7605C6.72 7.1025 6.5 7.5775 6.5 8.1V17.6C6.5 18.645 7.4 19.5 8.5 19.5H17.5C18.33 19.5 19.04 19.025 19.34 18.341L22.36 11.6435C22.45 11.425 22.5 11.197 22.5 10.95V9.05Z" fill="#595959" />
                            </svg>
                            :
                            <svg width="20" height="20" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.3413 4.62L13.6525 8.11208C13.5075 8.825 13.7009 9.56208 14.16 10.1179C14.6192 10.6738 15.2959 11 16.0209 11H22.6667V12.305L19.5613 19.4583H9.78585C9.56835 19.4583 9.37502 19.265 9.37502 19.0475V9.57417L14.3413 4.62ZM15.4167 0.125L7.67127 7.87042C7.2121 8.32958 6.95835 8.94583 6.95835 9.58625V19.0475C6.95835 20.6063 8.2271 21.875 9.78585 21.875H19.5734C20.4313 21.875 21.2167 21.4279 21.6517 20.7029L24.8779 13.2717C25.0109 12.9696 25.0834 12.6433 25.0834 12.305V11C25.0834 9.67083 23.9959 8.58333 22.6667 8.58333H16.0209L17.1325 2.96458C17.1929 2.69875 17.1567 2.40875 17.0359 2.16708C16.7579 1.62333 16.4075 1.12792 15.9725 0.692917L15.4167 0.125ZM3.33335 8.58333H0.916687V21.875H3.33335C3.99794 21.875 4.54169 21.3313 4.54169 20.6667V9.79167C4.54169 9.12708 3.99794 8.58333 3.33335 8.58333Z" fill="#595959" />
                            </svg>
                          }
                        </div>
                      </Fade>
                      <div className='px-2 py-3'>
                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleToggleInfo()} style={{ transform: `rotate(${rotationInfoArrowAngle}deg)`, transition: 'transform 0.5s ease' }}>
                          <path d="M14.12 -1.64355e-07L8 6.79892L1.88 -1.23441e-06L7.78664e-07 2.09312L8 11L16 2.09312L14.12 -1.64355e-07Z" fill="#595959" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <Collapse in={toggleinfo}>
                    <div className='mt-3.5 text-xs md:text-sm font-space-grotesk'>
                      {modelCategories[state.current]?.info}
                    </div>
                    <button className={`flex items-center w-full py-2 border border-white rounded-lg mt-5 hover:bg-[#ffa401] justify-between font-space-grotesk tracking-wider text-black font-medium text-[1.3em]  ${state.interestedCategory.has(category) ? 'interested px-10 ' : 'not-interested px-6 '} `} onClick={() => handleInterested()}>
                      {interested.includes(category) ?
                        <>
                          <svg width="23" height="23" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 19.5H4.5V8.1H0.5V19.5ZM22.5 9.05C22.5 8.005 21.6 7.15 20.5 7.15H14.19L15.14 2.8085L15.17 2.5045C15.17 2.115 15 1.754 14.73 1.4975L13.67 0.5L7.09 6.7605C6.72 7.1025 6.5 7.5775 6.5 8.1V17.6C6.5 18.645 7.4 19.5 8.5 19.5H17.5C18.33 19.5 19.04 19.025 19.34 18.341L22.36 11.6435C22.45 11.425 22.5 11.197 22.5 10.95V9.05Z" fill="#595959" />
                          </svg>
                          Form Follows Interest
                        </>
                        :
                        <>
                          <svg width="23" height="23" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.3413 4.62L13.6525 8.11208C13.5075 8.825 13.7009 9.56208 14.16 10.1179C14.6192 10.6738 15.2959 11 16.0209 11H22.6667V12.305L19.5613 19.4583H9.78585C9.56835 19.4583 9.37502 19.265 9.37502 19.0475V9.57417L14.3413 4.62ZM15.4167 0.125L7.67127 7.87042C7.2121 8.32958 6.95835 8.94583 6.95835 9.58625V19.0475C6.95835 20.6063 8.2271 21.875 9.78585 21.875H19.5734C20.4313 21.875 21.2167 21.4279 21.6517 20.7029L24.8779 13.2717C25.0109 12.9696 25.0834 12.6433 25.0834 12.305V11C25.0834 9.67083 23.9959 8.58333 22.6667 8.58333H16.0209L17.1325 2.96458C17.1929 2.69875 17.1567 2.40875 17.0359 2.16708C16.7579 1.62333 16.4075 1.12792 15.9725 0.692917L15.4167 0.125ZM3.33335 8.58333H0.916687V21.875H3.33335C3.99794 21.875 4.54169 21.3313 4.54169 20.6667V9.79167C4.54169 9.12708 3.99794 8.58333 3.33335 8.58333Z" fill="#595959" />
                          </svg>
                          I'm Interested
                        </>}
                    </button>
                  </Collapse>
                </div>
              </Collapse>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

