import React, {useState } from 'react';
import './index.css';
import Explore from './components/Explore';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader';

export default function App() {    
  const [journeyStarted, setJourneyStarted] = useState(false);
  return (
      <div className='overscroll-none h-screen fixed'>
        <div className='overscroll-none h-screen w-screen'>
          {!journeyStarted && <Loader setJourneyStarted={setJourneyStarted}/>}
          <Router>
            <Routes>
              <Route path="/" element={<Explore journeyStarted={journeyStarted} />} />
            </Routes>
          </Router>
        </div>
      </div>
  );
}
