import React from 'react'

export default function About({ setIsAboutOpen }) {
    return (
        <div className='flex flex-col absolute max-md:h-screen max-md:items-center top-1 max-[768px]:left-1 md:top-28 max-md:justify-center md:left-5'>
            <div class="bg-white p-4 rounded max-[768px]:w-[90%] w-[390px] info-box border border-white">
                <div className='mb-8'>
                    <div className='font-space-grotesk tracking-wider text-black font-bold text-[1.3em]  '>About Us</div>
                    <button className="text-gray-500 absolute top-0 right-2 text-lg p-4 font-space-grotesk" onClick={() => setIsAboutOpen(false)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z" fill="#757575" />
                        </svg>
                    </button>
                </div>
                <div className='flex items-center'>
                    <div className=' text-xs md:text-sm font-space-grotesk'>
                    CODIN+Edu envisions a world where design innovation is not just a privilege but a
                    universal opportunity. We aim to empower knowledge seekers to match the evolving industry,
                    go beyond to challenge its norms, push its boundaries and in-turn unlock their own full
                    potential.
                    <div className=' text-xs md:text-sm font-space-grotesk mt-5'>
                    CODIN+Edu is CODIN Global’s transformative design education platform that addresses 
                    the shortcomings of traditional design education and the design industry. We bring 
                    together expertise, innovation, and accessibility to redefine how design is taught 
                    and learned. We offer innovative courses, workshops, design studios, and help students,
                    tutors & universities to bridge the gap between theory and practice, preparing students 
                    for real-world challenges and opportunities in the design industry.
                    </div>
                    <div className=' text-xs md:text-sm font-space-grotesk mt-5'>
                    Welcome to our community!
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
