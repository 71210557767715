import React from 'react'

export default function Footer() {
    return (
        <div className='justify-center flex items-center text-center'>
            <div className='absolute bottom-20 max-md:w-[90%] md:bottom-16 text-[10px] md:text-xs mt-4 font-source-code-pro cursor-pointer z-[100]' >
                © 2023-2024, CODIN. All rights reserved. Website developed by <span className='hover:text-amber-500 hover:underline' onClick={() => window.location.href = 'https://gwdcollective.com/'}>GWD Collective</span>.
            </div>
        </div>
    )
}

